import React from "react";
import { Home } from "../components/Home";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ContactUs from "../components/Contact/ContactUs";
import { ErrorPage } from "../components/ErrorPage/404";
import { AboutUsPage } from "../components/AboutUsPage";
import { ComingSoon } from "../components/InnerSection/ComingSoon";

class MainRoutes extends React.Component {
  render() {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home/>} exact/>
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/error-page" element={<ErrorPage />} />
          <Route path="/about-us" element={<AboutUsPage />} />
          <Route path="*" element={<ComingSoon />} />
        </Routes>
      </BrowserRouter>
    );
  }
}
export default MainRoutes;
