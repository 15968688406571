import Header from "../HeaderSection/Header";

export const ComingSoon = () => {
  return (
    <div className="homepage-3 inner inner-pages">
      <div className="main">
        <Header imageData={"/img/logo-white.webp"} />
        <section
          id="home"
          className="overflow-hidden section bg-overlay h-100vh"
        >
          <div className="container h-100">
            <div className="row align-items-center justify-content-center h-100">
              <div className="col-12 col-md-7">
                <div className="text-center welcome-intro">
                  <h1 className="text-white">Coming Soon!</h1>
                  {/* <p className="my-4 text-white">{initData.content}</p>
                  <div className="mb-4 countdown-times">
                    <div
                      className="countdown d-flex justify-content-center"
                      data-date="2021-12-09"
                    />
                  </div>
                  <div className="mx-auto subscribe-form d-flex align-items-center">
                    <input
                      type="email"
                      className="form-control"
                      placeholder="info@yourmail.com"
                    />
                    <button type="submit" className="btn btn-bordered">
                      {initData.btnText}
                    </button>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};
