import { makeStyles } from "@material-ui/core";
import { useRef } from "react";
import { pressItems } from "../../data/press-items";
import { useIntersection } from "../../Hooks/useIntersection";

const useStyles = makeStyles((theme) => ({
  root: {},
  pressSection: {},
  monthNumber: {
    maxWidth: "50px",
  },
  monthArticles: {},
  yearSection: {
    display: "flex",
    marginTop: "30px",

    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  yearNumber: {
    minWidth: "300px",
    display: "flex",
    justifyContent: "center",
    position: "relative",
    "&:before": {
      content: '" "',
      display: "block",
      position: "absolute",
      top: 0,
      right: "20px",
      width: "1px",
      height: "100%",
      background: "gray",
    },
    "& span": {
      fontSize: "36px",
    },

    [theme.breakpoints.down("sm")]: {
      "&:before": {
        display: "none",
      },
    },
  },
  monthSection: {
    display: "flex",
  },
  month: {},
  articleItem: {
    display: "flex",
    marginTop: "50px",

    "&:first-child": {
      marginTop: 0,
    },

    [theme.breakpoints.down("sm")]: {
      marginTop: 20,
    },
  },
  articleContent: {
    paddingLeft: "5rem",
    maxWidth: "calc(((100vw - 2rem) / 12) * 8)",
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: "calc(((100vw - 2rem) / 12) * 8)",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      maxWidth: "100%",
      flexBasis: "100%",

      paddingLeft: 0,
    },
  },
  articleTitle: {
    fontSize: "26px",
    margin: "0px 0",
    display: "block",
    [theme.breakpoints.down("sm")]: {
      fontSize: 20,
    },
  },
}));

export const PressSection = () => {
  const classes = useStyles();
  const blogsRef = useRef(null);

  const inViewport = useIntersection(blogsRef, "-100px"); // Trigger as soon as the element becomes visible
  if (inViewport) {
    const press_title = document.querySelector("#press_title");
    setTimeout(function () {
      press_title.classList.add("fade-in");
    }, 175);
  }

  return (
    <section className="bg-white section discover-area" id="press">
      <div
        className="inner-container"
        style={{
          marginBottom: 60,
          paddingTop: 60,
        }}
      >
        <div className="row">
          <div className="order-1 col-12 order-lg-1">
            <div
              className="pt-4 discover-text pt-lg-0"
              style={{ borderBottom: "solid 1px #444444" }}
            >
              <h2
                ref={blogsRef}
                id="press_title"
                style={{
                  fontWeight: 400,
                  color: "#000",
                  opacity: 0,
                  paddingBottom: 40,
                }}
              >
                <span style={{ color: "#3fcfab", fontWeight: 500 }}>Press</span>
              </h2>
            </div>
          </div>
        </div>
        <div className={classes.pressSection}>
          {pressItems.map((yearItem) => (
            <div className={classes.yearSection} key={Math.random().toString(36).replace(/[^a-z]+/g, '')}>
              <div className={classes.yearNumber}>
                <span>{yearItem?.year}</span>
              </div>
              <div className={classes.monthSection}>
                <div className={classes.monthArticles}>
                  {yearItem?.items.map((blogItem) => (
                    <div className={classes.articleItem} key={Math.random().toString(36).replace(/[^a-z]+/g, '')}>
                      <div className={classes.articleContent}>
                        <div className={classes.articleMeta}>[Announcement]</div>
                        <a
                          href={blogItem.link}
                          target="_blank"
                          className={classes.articleTitle}
                          rel="noreferrer"
                        >
                          {blogItem.title}
                        </a>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
