import { useRef } from "react";
import { investors } from "../../data/investors";

import { useIntersection } from "../../Hooks/useIntersection";
import styles from "./InvestorSection.module.css";

const RenderInvestors = ({ investors }) => {
  return (
    <>
      {investors.map((investor, index) => (
        <a href={investor.link} target="_blank" key={index} rel="noreferrer">
          <div className={styles.Investor}>
            <img className={styles.InvestorImg}
              src={investor.logo}
              alt="investor logo"
            />
          </div>
        </a>
      ))}
    </>
  );
};

export const InvestorsSection = () => {
  const investorsRef = useRef();
  const inviewInvestors = useIntersection(investorsRef, "-100px"); // Trigger as soon as the element becomes visible
  if (inviewInvestors) {
    const teamSection = document.querySelector("#investors_section");
    setTimeout(function () {
      teamSection.classList.add("fade-in");
    }, 175);
  }

  return (
    <>
      <div
        style={{
          marginBottom: 60,
          paddingTop: 100,
        }}
        className="inner-container"
        id="team"
      >
        <div className="row">
          <div className="order-1 col-12 order-lg-1">
            <div
              className="pt-4 discover-text pt-lg-0"
              style={{ borderBottom: "solid 1px #444444" }}
            >
              <h2
                ref={investorsRef}
                id="investors_section"
                style={{
                  fontWeight: 400,
                  color: "#000",
                  opacity: 1,
                  paddingBottom: 40,
                }}
              >
                <span style={{ color: "#3fcfab", fontWeight: 500 }}>
                  Investors
                </span>
              </h2>
            </div>
          </div>
        </div>
        <div className={styles.InvestorsRow}>
          <RenderInvestors investors={investors} />
        </div>
      </div>
    </>
  );
};
