import Grid from "@material-ui/core/Grid";
import { ArrowForward, Height } from "@material-ui/icons";
import React, { useRef, useState, useEffect } from "react";
import { blogItems } from "../../data/blog-items";
import { useIntersection } from "../../Hooks/useIntersection";
import styles from "./BlogSection.module.css";

export const BlogSection = () => {
  const blogRef = useRef();
  const inViewport = useIntersection(blogRef, "-100px"); // Trigger as soon as the element becomes visible

  const [width, setWidth] = useState(window.innerWidth);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);
  let isMobile = (width <= 768);
  let iOS = (() => {
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
      // iPad on iOS 13 detection
      || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  })();

  if (inViewport) {
    const blogRef = document.querySelector("#blog_title");
    setTimeout(function () {
      blogRef.classList.add("fade-in");
    }, 175);
  }

  return (
    <section className="section discover-area" id="blog">
      <div
        className="inner-container"
        style={{
          marginBottom: 40,
          paddingTop: 60,
        }}
      >
        <div className="row">
          <div className="order-1 col-12 order-lg-1">
            <div
              className="pt-4 discover-text pt-lg-0"
              style={{ borderBottom: "solid 1px #444444" }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  paddingBottom: 40,
                }}
              >
                <h2
                  ref={blogRef}
                  id="blog_title"
                  style={{
                    fontWeight: 400,
                    color: "#000",
                    opacity: 0,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <span style={{ color: "#3fcfab", fontWeight: 500 }}>
                    Blog Posts
                  </span>
                </h2>
                <a
                  href="https://medium.com/swlh/the-evolution-of-intelligence-in-robots-part-1-2ad38a92800"
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    display: "flex",
                  }}
                >
                  <span>Read Nimble Blog</span>

                  <ArrowForward style={{ fontSize: 24 }} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style={isMobile ?
        { paddingLeft: 20, paddingRight: 20, marginBottom: 20 } :
        { paddingLeft: 60, paddingRight: 60, marginBottom: 60 }}>
        <Grid container>
          {blogItems.map((item) => (
            <Grid item xs={6} md={6} lg={3}
              key={Math.random().toString(36).replace(/[^a-z]+/g, '')}>
              <div
                className="pnp-card-body"
                id="pnp"
                style={{
                  minHeight: "auto",
                  padding: 0,
                  overflow: "hidden",
                }}
              >
                <div className="pnp-card-top-row">
                  <span
                    style={{
                      textTransform: "uppercase",
                      fontSize: isMobile ? "0.55em" : "1em"
                    }}
                  >
                    - {item.title}
                  </span>
                </div>
                <a href={item.video} target="_blank" rel="noreferrer">
                  {item.image.endsWith("webp")
                    ?
                    <div
                      className={`${styles.TechImageContainer} tech-image-container`}
                      style={{
                        padding: 0,
                        background: `url(${item.image})`,
                        backgroundSize: "cover",
                      }}>
                    </div>
                    : <video
                      loop
                      playsInline
                      autoPlay={true}
                      preload="auto"
                      muted
                      className={`${styles.TechImageContainer} tech-image-container`}
                      style={{
                        display: "flex",
                        width: "100%",
                        height: "100%",
                        padding: 0,
                        backgroundSize: "cover"
                      }} key={item.image}>
                      <source src={iOS ? item.image.replace("webm", "mp4") : item.image}
                        type={iOS ? "video/mp4;codecs=\"avc1\"" : "video/webm;codecs=\"vp9\""} />
                    </video>
                  }
                </a>
              </div>
            </Grid>
          ))}
        </Grid>
      </div>
    </section>
  );
};
