import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import React, { useState } from "react";
// import { HashLink } from "react-router-hash-link";
import { useMouseScroll } from "../../Hooks/useMouseScroll";
import { StatusBar } from "./../StatusBar";
import styles from "./Header.module.css";

const Header = (props) => {
  const { inverse } = props;
  const [menuActive, setMenuActive] = useState();

  const scrollingDirection = useMouseScroll();

  return (
    <>
      <header className="main_head">
        <div
          className={`${styles.MainHeader} bottom-header`}
          style={{
            visibility: scrollingDirection === "down" ? "hidden" : "visible",
            opacity: scrollingDirection === "down" ? 0 : 1,
            marginTop: scrollingDirection === "down" ? -100 : 0,
            transition: "all 0.3s ease-in-out",
          }}
        >
          <StatusBar
            message="Learn more about Nimble AI"
            status={false}
            link="/"
          />
          <div className={`${styles.MainHeader_Menu} header_menu`}>
            <a
              className="navbar-brand"
              href="/"
              style={{ marginRight: 40, paddingTop: 0, paddingBottom: 0 }}
            >
              <img
                className="navbar-brand-regular"
                src="/img/logo.webp"
                alt="Nimble.ai"
                style={{ width: 120 }}
              />
            </a>
            <div className="nav_btn">
              <div></div>
            </div>

            <ul>
              <li
                className={`menu-item-has-children ${styles.MenuItem_HasChildren}`}
              >
                <a href="/#solutions">Solutions</a>
                <div className="sub-menu-wraper">
                  <div className="submenu-holder">
                    <div className="row">
                      <div className="col-md-6 left-menu-holder">
                        <div className="holder">
                          <h5 className="heading">_Solutions</h5>
                          <ul className="sub-menu">
                            <li>
                              <a href="/#solutions">Goods-to-Robot</a>
                            </li>
                            <li>
                              <a href="/#solutions">Put-Wall Sorting</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-md-6 right-content-holder">
                        <div className="holder">
                          <p>Learn more about Nimble.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <a
                  href="/careers"
                >
                  Careers
                </a>
              </li>
              <li>
                <a href="/#team">Team</a>
              </li>
              <li>
                <a href="/#press">Press</a>
              </li>
              <li
                className={`menu-item-has-children ${styles.MenuItem_HasChildren}`}
              >
                <a href="/">Company</a>
                <div className="sub-menu-wraper">
                  <div className="submenu-holder">
                    <div className="row">
                      <div className="col-md-6 left-menu-holder">
                        <div className="holder">
                          <h5 className="heading">_Company</h5>
                          <ul className="sub-menu">
                            <li>
                              <a href="/about-us">About Us</a>
                            </li>
                            <li>
                              <a href="/#blog">Blog</a>
                            </li>
                            <li>
                              <a href="/store">Store</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-md-6 right-content-holder">
                        <div className="holder">
                          <p>Learn more about Nimble.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>

            <a href="/contact-us">
              <div
                id="contact-us"
                style={{ display: "flex", alignItems: "center" }}
              >
                <div className={styles.MainHeader_Button}>
                  <span>Connect</span>
                  <ArrowForwardIcon style={{ fontSize: 16 }} />
                </div>
              </div>
            </a>
          </div>
        </div>
      </header>

      {/* BELOW IS MOBILE VERSION OF MENU HEADER */}
      <div className="mobile-head">
        <header
          className={`navbar navbar-sticky navbar-expand-lg ${
            inverse === true ? "navbar-dark" : ""
          }`}
        >
          <div
            className="container position-relative"
            style={{ maxWidth: "100%", paddingRight: 60 }}
          >
            <a className="navbar-brand" href="/">
              <img
                className="navbar-brand-regular"
                src="/img/logo.webp"
                alt="Nimble.ai"
                style={{ width: 120 }}
              />
              <img
                className="navbar-brand-sticky"
                src="/img/logo.webp"
                alt="Nimble.ai"
                style={{ width: 120 }}
              />
            </a>
            <button
              className="navbar-toggler d-lg-none"
              type="button"
              data-toggle="navbarToggler"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div className="navbar-inner">
              {/*  Mobile Menu Toggler */}
              <button
                className="navbar-toggler d-lg-none"
                type="button"
                data-toggle="navbarToggler"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon" />
              </button>
              <nav>
                <ul className="navbar-nav" id="navbar-nav">
                  <li
                    className="nav-item dropdown"
                    onMouseEnter={() => setMenuActive("solutions")}
                    onMouseLeave={() => setMenuActive(null)}
                  >
                    <a
                      className="nav-link"
                      href="/#"
                      id="navbarDropdownMenuLink"
                      data-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <span className="menu-item-text">
                        Solutions
                        {menuActive === "solutions" ? (
                          <span style={{ color: "#3fcfab" }}> -</span>
                        ) : (
                          " +"
                        )}
                      </span>
                    </a>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="navbarDropdownMenuLink"
                    >
                      <li>
                        <a className="dropdown-item" href="/#solutions">
                          Goods-to-Robot
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="/#solutions">
                          Put-Wall Sorting
                        </a>
                      </li>
                    </ul>
                  </li>

                  {/* <li
                    className="nav-item dropdown"
                    onMouseEnter={() => setMenuActive("case-studies")}
                    onMouseLeave={() => setMenuActive(null)}
                  >
                    <a
                      className="nav-link"
                      href="/#"
                      id="navbarDropdownMenuLink"
                      data-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <span>
                        Case Studies
                        {menuActive === "case-studies" ? (
                          <span style={{ color: "#3fcfab" }}> -</span>
                        ) : (
                          " +"
                        )}
                      </span>
                    </a>
                  </li> */}
                  <li className="nav-item">
                    <a href="/pricing" className="nav-link">
                      Pricing
                    </a>
                  </li>

                  <li className="nav-item">
                    <a
                      href="/careers"
                      className="nav-link"
                    >
                      Careers
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="/#team" className="nav-link hash-link">
                      Team
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="/#press" className="nav-link hash-link">
                      Press
                    </a>
                  </li>

                  <li className="nav-item dropdown ">
                    <a
                      className="nav-link"
                      href="/#"
                      id="navbarDropdownMenuLink"
                      data-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <span className="menu-item-text">
                        Company
                        {menuActive === "solutions" ? (
                          <span style={{ color: "#3fcfab" }}> -</span>
                        ) : (
                          " +"
                        )}
                      </span>
                    </a>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="navbarDropdownMenuLink"
                    >
                      <li>
                        <a className="dropdown-item" href="/about-us">
                          About Us
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item hash-link" href="/#blog">
                          Blog
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="/store">
                          Store
                        </a>
                      </li>
                    </ul>
                  </li>

                  <li
                    style={{
                      padding: "20px",
                    }}
                  >
                    <a href="/contact-us">
                      <div
                        id="contact-us"
                        className="mobile-contact-btn"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <div
                          className={`${styles.MainHeader_Button} ${styles.Mobile_Button}`}
                        >
                          <span>Connect</span>
                          <ArrowForwardIcon style={{ fontSize: 16 }} />
                        </div>
                      </div>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </header>
      </div>
    </>
  );
};

export default Header;
