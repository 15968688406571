import FooterSection from "../FooterSection/Footer";
import Header from "../HeaderSection/Header";
import AboutUs from "../MainContent/AboutUs";

export const AboutUsPage = () => {
  return (
    <div className="homepage-6">
      <div id="scrollUp" title="Scroll To Top">
        <i className="fas fa-arrow-up" />
      </div>

      <div className="main">
        <Header />
        <AboutUs />
        <FooterSection />
      </div>
    </div>
  );
};
