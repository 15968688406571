export const ErrorPage = () => {
  return (
    <div className="inner inner-pages">
      <div className="main">
        <section
          id="home"
          className="overflow-hidden section welcome-area inner-area bg-overlay h-100vh"
        >
          <div className="container h-100">
            <div className="row align-items-center justify-content-center h-100">
              <div className="col-12 col-md-7">
                <div className="text-center welcome-intro error-area">
                  <h1 className="text-white">404</h1>
                  <p className="my-4 text-white">
                    The page you are looking for might have been removed had its
                    name changed or is temporarily unavailable.
                  </p>
                  <a href="/" className="btn sApp-btn text-uppercase">
                    Go To Home Page
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};
