import styles from "./StatusBar.module.css";

export const StatusBar = ({ status = true, message, link }) => {
  if (!status) return null;

  return (
    <a className={styles.StatusBar} href={link || "/"}>
      {message}
    </a>
  );
};
