import { useRef } from "react";
import { Customers } from "../../data/customers";

import { useIntersection } from "../../Hooks/useIntersection";
import styles from "./CustomersSection.module.css";

const RenderCustomers = ({ customers }) => {
  return (
    <>
      {customers.map((customer, index) => (
        <a href={customer.link} target="_blank" key={index} rel="noreferrer">
          <div className={styles.Customer}>
            <img className={styles.CustomerImg}
              src={customer.logo}
              alt={customer.name}
            />
          </div>
        </a>
      ))}
    </>
  );
};

export const CustomersSection = () => {
  const customersRef = useRef();
  const inviewCustomers = useIntersection(customersRef, "-100px"); // Trigger as soon as the element becomes visible
  if (inviewCustomers) {
    const teamSection = document.querySelector("#customers_section");
    setTimeout(function () {
      teamSection.classList.add("fade-in");
    }, 175);
  }

  return (
    <>
      <div
        style={{
          marginBottom: 60,
          paddingTop: 100,
        }}
        className="inner-container"
        id="team"
      >
        <div className="row">
          <div className="order-1 col-12 order-lg-1">
            <div
              className="pt-4 discover-text pt-lg-0"
              style={{ borderBottom: "solid 1px #444444" }}
            >
              <h2
                ref={customersRef}
                id="customers_section"
                style={{
                  fontWeight: 400,
                  color: "#000",
                  opacity: 1,
                  paddingBottom: 40,
                }}
              >
                <span style={{ color: "#3fcfab", fontWeight: 500 }}>
                  Customers.
                </span>
                &nbsp;Trusted by Iconic Brands
              </h2>
            </div>
          </div>
        </div>
        <div className={styles.CustomersRow}>
          <RenderCustomers customers={Customers} />
        </div>
      </div>
    </>
  );
};
