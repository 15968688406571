import { useMemo, useEffect, useRef, useState } from "react";
import { NavigateBefore, NavigateNext } from "@material-ui/icons";
import { useWindowSize } from "../../Hooks/useWindowSize";

import styles from "./AnimatedCusor.module.css";
import { useMousePosition } from "../../Hooks/useMousePosition";
import { useDevice } from "../../Hooks/useDevice";

export const AnimatedCursor = () => {
  const cursorDotOutline = useRef();
  const requestRef = useRef();
  const previousTimeRef = useRef();
  const cursorVisible = useRef(false);
  const cursorEnlarged = useRef(false);

  const [mouseSide, setMouseSide] = useState("left");

  const mousePosition = useMousePosition();

  const isMobile = useDevice();
  const { width, height } = useWindowSize();

  const [calcX, setCalcX] = useState(0);
  const [calcY, setCalcY] = useState(0);

  useEffect(() => {
    if (mousePosition.x > width / 2) {
      setMouseSide("right");
    } else {
      setMouseSide("left");
    }
  }, [mousePosition, width]);

  /**
   * Mouse Moves
   */
  const onMouseMove = (event) => {
    positionDot(event);
  };

  const onMouseEnter = () => {
    cursorVisible.current = true;
    toggleCursorVisibility();
  };

  const onMouseLeave = () => {
    cursorVisible.current = false;
    toggleCursorVisibility();
  };

  const onMouseDown = () => {
    cursorEnlarged.current = true;
    toggleCursorSize();
  };

  const onMouseUp = () => {
    cursorEnlarged.current = false;
    toggleCursorSize();
  };

  /**
   * Hooks
   */
  useEffect(() => {
    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener("mouseenter", onMouseEnter);
    document.addEventListener("mouseleave", onMouseLeave);
    document.addEventListener("mousedown", onMouseDown);
    document.addEventListener("mouseup", onMouseUp);
    window.requestAnimationFrame =
      window.requestAnimationFrame ||
      window.mozRequestAnimationFrame ||
      window.webkitRequestAnimationFrame ||
      window.msRequestAnimationFrame;
    requestRef.current = window.requestAnimationFrame(animateDotOutline);

    // Handle Link Hovers
    handleLinkHovers();

    return () => {
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseenter", onMouseEnter);
      document.removeEventListener("mouseleave", onMouseLeave);
      document.removeEventListener("mousedown", onMouseDown);
      document.removeEventListener("mouseup", onMouseUp);
      window.cancelAnimationFrame(requestRef.current);
    };
  }, []);

  const winDimensions = { width, height };
  let endX = winDimensions.width / 2;
  let endY = winDimensions.height / 2;

  /**
   * Position Dot (cursor)
   * @param {event}
   */
  const positionDot = (e) => {
    cursorVisible.current = true;
    toggleCursorVisibility();
    // Position the dot
    endX = e.pageX;
    endY = e.pageY;
  };

  /**
   * Toggle Cursor Visiblity
   */
  const toggleCursorVisibility = () => {
    if (!cursorDotOutline.current) return;

    if (cursorVisible.current) {
      cursorDotOutline.current.style.top = endX;
      cursorDotOutline.current.style.left = endY;
      cursorDotOutline.current.style.opacity = 1;
    } else {
      cursorDotOutline.current.style.opacity = 0;
    }
  };

  /**
   * Toggle Cursor Size
   */
  const toggleCursorSize = () => {
    if (!cursorDotOutline.current) return;

    if (cursorEnlarged.current) {
      cursorDotOutline.current.style.transform =
        "translate(-50%, -50%) scale(1.5)";
    } else {
      cursorDotOutline.current.style.transform =
        "translate(-50%, -50%) scale(1)";
    }
  };

  const [hoverLink, setHoverLink] = useState(false);
  /**
   * Handle LInks
   * Applies mouseover/out hooks on all links
   * to trigger cursor animation
   */
  const handleLinkHovers = () => {
    document.querySelectorAll("a").forEach((el) => {
      el.addEventListener("mouseover", () => {
        cursorEnlarged.current = true;
        setHoverLink(true);
      });

      el.addEventListener("mouseout", () => {
        cursorEnlarged.current = false;
        setHoverLink(false);
      });
    });
  };

  /**
   * Animate Dot Outline
   * Aniamtes cursor outline with trailing effect.
   * @param {number} time
   */

  let x = mousePosition?.x;
  let y = mousePosition?.y;

  const followerPos = useMemo(() => {
    return {
      left: calcX,
      top: calcY,
    };
  }, [calcX, calcY]);

  const animateDotOutline = (time) => {
    if (previousTimeRef.current !== undefined) {
      endY = endY || 0;
      endX = endX || 0;
      x += (endX - x) / 8;
      y += (endY - y) / 8;

      setCalcX(x);
      setCalcY(y);
    }

    previousTimeRef.current = time;
    requestRef.current = requestAnimationFrame(animateDotOutline);
  };

  if (typeof navigator !== "undefined" && isMobile) {
    return null;
  }

  return (
    <>
      <div
        ref={cursorDotOutline}
        className={styles.Cursor}
        style={{
          ...followerPos,
        }}
      >
        {!hoverLink && (
          <>
            {mouseSide === "left" ? (
              <NavigateBefore fontSize="large" className={styles.CursorArrow} />
            ) : (
              <NavigateNext fontSize="large" className={styles.CursorArrow} />
            )}
          </>
        )}
      </div>
    </>
  );
};
