import { PressSection } from "../PressSection";
import FooterSection from "../FooterSection/Footer";
import Header from "../HeaderSection/Header";
import { HeroSection } from "../HeroSection/Hero";

import MainContent from "../MainContent/MainContent";
// import PlugAndPlay from "../MainContent/PlugAndPlay";
import { TeamsSection } from "../TeamsSection";
import { BlogSection } from "../BlogSection";
import { InvestorsSection } from "../InvestorSection";
import { CustomersSection } from "../CustomersSection";

export const Home = () => {
  return (
    <div className="homepage-6">
      <div id="scrollUp" title="Scroll To Top">
        <i className="fas fa-arrow-up" />
      </div>

      <div className="main">
        <Header imageData={"/img/logo-white.webp"} />
        <HeroSection />
        <MainContent />
        <PressSection />
        <CustomersSection />
        <TeamsSection />
        <InvestorsSection />
        <BlogSection />
        <FooterSection />
      </div>
    </div>
  );
};
