export const pressItems = [
  {
    year: 2022,
    items:[
      {
        title:
          "Robotic Arms Are Using Machine Learning to Reach Deeper Into Distribution.",
        link: "https://www-wsj-com.cdn.ampproject.org/c/s/www.wsj.com/amp/articles/robotic-arms-are-using-machine-learning-to-reach-deeper-into-distribution-11641852537",
      },
    ]
  },
  {
    year: 2021,
    items: [
      {
        title:
          "Nimble robots have picked more than 15 million objects, across 500,000 unique products, in production for several well-known brands.",
        link: "https://www.mmh.com/article/nimble_robotics_details_uptake_for_its_ai_enabled_picking_robots",
      },
      {
        title:
          "Nimble Robotics Raises $50 Million to Build the Future of On-Demand Robotic Fulfillment: Nimble adds AI pioneers Fei-Fei Li and Sebastian Thrun to its Board",
        link: "https://www.businesswire.com/news/home/20210311005117/en/Nimble-%C2%AE-Robotics-Raises-50-Million-to-Build-the-Future-of-On-Demand-Robotic-Fulfillment",
      },
      {
        title:
          "MultiChannel Merchant: Nimble Robotics Takes In $50M, Adds AI Luminaries",
        link: "https://multichannelmerchant.com/operations/nimble-robotics-takes-in-50m-adds-ai-luminaries/",
      },
      {
        title: "Nimble highlighted in STIQ Goods-to-person robotics report",
        link: "https://www.styleintelligence.com/products/market-report-goods-to-person-ecommerce-fulfilment-robotics-2021",
      },
      {
        title:
          "Nimble highlighted in STIQ Online Grocery and fulfillment technology report",
        link: "https://www.styleintelligence.com/products/market-report-e-grocery-infrastructure-2021",
      },
      {
        title:
          "Freight Waves: Nimble Robotics raises $50M for fulfillment automation ",
        link: "https://www.freightwaves.com/news/nimble-robotics-raises-$50m-for-fulfillment-automation",
      },
    ],
  },
  {
    year: 2020,
    items: [
      {
        title:
          "Forbes 30 Under 30: Nimble’s founder and CEO, Simon Kalouche, makes the 30 Under 30 List",
        link: "https://www.forbes.com/profile/simon-kalouche/?sh=6c511efa6329",
      },
    ],
  },
];
