import React, { useRef } from "react";

import { useIntersection } from "../../Hooks/useIntersection";

const AboutUs = () => {
  const aboutUs = useRef();
  const inViewport = useIntersection(aboutUs, "-100px"); // Trigger as soon as the element becomes visible
  if (inViewport) {
    const aboutUs = document.querySelector("#about-us");
    setTimeout(function () {
      aboutUs.classList.add("fade-in");
    }, 175);
  }

  return (
    <section className="bg-white section discover-area" id="about">
      <div className="about-image" />
      <div
        className="row"
        style={{
          paddingTop: 60,
          paddingLeft: 60,
        }}
      >
        <div className="order-1 col-12 order-lg-1">
          {/* Discover Text */}
          <div className="pt-4 discover-text pt-lg-0">
            <h2
              ref={aboutUs}
              id="about-us"
              className="pb-4"
              style={{ fontWeight: 400, opacity: 0 }}
            >
              <span style={{ color: "#3fcfab", fontWeight: 500 }}>
                About Us
              </span>
            </h2>
          </div>
        </div>
      </div>
      <div
        style={{
          padding: "0 60px",
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div
            className="pnp-box-left"
            style={{
              width: "50%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <p
              className="alt-p"
              style={{
                fontSize: 24,
                margin: "10px 0",
              }}
            >
              _We’re creating robot AI to build the next generation of
              intelligent robotic fulfillment centers.
            </p>
          </div>
          <div className="pnp-box-right" style={{ width: "50%" }}>
            <p className="fw-normal">
              Nimble was founded from the artificial intelligence labs at
              Stanford and Carnegie Mellon. Nimble has raised $50M in capital
              from prestigious investors including Accel, DNS Capital, GSR
              Ventures, and Reinvent.
            </p>
            <p className="mt-4 fw-normal">
              Our board of directors include AI luminaries Sebastian Thrun
              (founder of GoogleX, Waymo, Udacity, and KittyHawk; Stanford
              Professor; considered the father of autonomous vehicles) and
              Fei-Fei Li (former Chief Scientist of AI at Google and Director of
              Stanford's AI Lab).
            </p>
            <p className="mt-4 fw-normal">
              Our product uniquely fuses robotics, computer vision, deep
              imitation, self-supervised and reinforcement learning,
              teleoperation, intelligent hardware design and a lot of real-time
              software to afford robots an unprecedented level of dexterity
              today, while training them to become increasingly intelligent and
              autonomous over time.
            </p>
            <p className="mt-4 fw-normal">
              We embrace challenges and strive to make the impossible possible
              each day. We’re not in this to do what’s easy or to be mediocre.
              We want to create something legendary and leave our mark on the
              world. We’re ambitious, we’re gritty, we’re humble, we’re
              passionate overachievers and we’re relentlessly resourceful in
              pursuit of our goals. If this sounds like you, please apply!
            </p>
          </div>
        </div>
      </div>
      <div className="team-photo" id="team">
        <img src="/img/team/all.webp" alt="All Team Members" />
      </div>
    </section>
  );
};

export default AboutUs;
