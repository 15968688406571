import { useRef } from "react";
import { LeadMemebers, TeamMembers } from "../../data/team-members";
import { useIntersection } from "../../Hooks/useIntersection";
import styles from "./TeamsSection.module.css";

const RenderedPeople = ({ members, size }) => {
  return (
    <>
      {members.map((person, index) => (
        <a href={person.linkedIn} target="_blank" key={index} rel="noreferrer" >
          <div
            className={size === "medium" ? styles.PersonSmall : styles.Person}
            style={{
              backgroundImage: `url(${person.photo})`,
            }}
          >
            <div className={styles.PersonInfo}>
              <div>
                <span>
                  {person.name}
                </span>
                <br />
                <span>{person.title}</span>
              </div>
              <div className={styles.PersonExp}>
                {person?.exp.map((expItm) => (
                  <p key={Math.random().toString(36).replace(/[^a-z]+/g, '')}>{expItm}</p>
                ))}
              </div>
              <div className={styles.CompanyLogos}>
                {person?.logo && <img src={person.logo} alt="company logos" />}
              </div>
            </div>
          </div>
        </a>
      ))}
    </>
  );
};

export const TeamsSection = () => {
  const teamRef = useRef();
  const inviewTeam = useIntersection(teamRef, "-100px"); // Trigger as soon as the element becomes visible
  if (inviewTeam) {
    const teamSection = document.querySelector("#team_section");
    setTimeout(function () {
      teamSection.classList.add("fade-in");
    }, 175);
  }

  return (
    <>
      <div
        className="inner-container"
        style={{
          marginBottom: 60,
          paddingTop: 100,
        }}
        id="team"
      >
        <div className="row">
          <div className="order-1 col-12 order-lg-1">
            <div
              className="pt-4 discover-text pt-lg-0"
              style={{ borderBottom: "solid 1px #444444" }}
            >
              <h2
                ref={teamRef}
                id="team_section"
                style={{
                  fontWeight: 400,
                  color: "#000",
                  opacity: 1,
                  paddingBottom: 40,
                }}
              >
                <span style={{ color: "#3fcfab", fontWeight: 500 }}>
                  Our Team.
                </span>
                &nbsp;World-class in Robotics, AI, and Logistics
              </h2>
            </div>
          </div>
        </div>
        <div className={styles.TeamRow}>
          <RenderedPeople members={LeadMemebers} />
        </div>
        <div className={styles.TeamRow}>
          <RenderedPeople members={TeamMembers} size="medium" />
        </div>
      </div>
    </>
  );
};
