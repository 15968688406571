import { useEffect, useRef, useState } from "react";

export const useMouseScroll = () => {
  const lastScrollPosRef = useRef(0);

  const [scrollingDirection, setScrollingDirection] = useState();

  useEffect(() => {
    const scrollHandler = () => {
      const st = window.pageYOffset || document.documentElement.scrollTop; // Credits: "https://github.com/qeremy/so/blob/master/so.dom.js#L426"
      if (st > lastScrollPosRef.current) {
        setScrollingDirection("down");
      } else {
        setScrollingDirection("up");
      }

      lastScrollPosRef.current = st <= 0 ? 0 : st;
    };

    window.addEventListener("scroll", scrollHandler);

    return () => {
      window.removeEventListener("scroll", scrollHandler);
    };
  }, []);

  return scrollingDirection;
};
