export const investors = [
  {
    title: "Reinvent Capital",
    link: "https://www.reinventcapital.com/investments",
    logo: "/img/brands/reinvent.webp",
  },
  {
    title: "Accel",
    link: "https://www.accel.com/people/sameer-gandhi",
    logo: "/img/brands/accel.webp",
  },
  {
    title: "DNS Capital",
    link: "https://www.dnscap.com/",
    logo: "/img/brands/dns.webp",
  },
  {
    title: "GSR Capital",
    link: "https://www.gsrventuresus.com/",
    logo: "/img/brands/gsr.webp",
  },
  {
    title: "Breyer Capital",
    link: "https://breyercapital.com/",
    logo: "/img/brands/breyercapital.webp",
  },
  {
    title: "GSR Capital",
    link: "https://www.taocap.com/",
    logo: "/img/brands/taocapitalpartners.webp",
  },
];
