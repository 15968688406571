export const Customers = [
  {
    name: "Puma",
    link: "https://www.puma.com",
    logo: "/img/brands/puma.webp",
  },
  {
    name: "Best Buy",
    link: "https://www.bestbuy.com",
    logo: "/img/brands/bestbuy.webp",
  },
  {
    name: "Victoria's Secret",
    link: "https://www.victoriassecret.com/",
    logo: "/img/brands/victoriassecret.webp",
  },
  {
    name: "Adore Me",
    link: "https://www.adoreme.com/",
    logo: "/img/brands/adoreme.webp",
  },
  {
    name: "iHerb",
    link: "https://www.iherb.com/",
    logo: "/img/brands/iherb.webp",
  },
];
