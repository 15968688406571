import { useEffect, useState } from "react";

export const useIntersection = (element, rootMargin) => {
  const [isVisible, setState] = useState(false);

  useEffect(() => {
    const currentElement = element.current;
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setState(entry.isIntersecting);
          observer.unobserve(currentElement);
        }
        setState(entry.isIntersecting);
      },
      { rootMargin }
    );

    if (element && currentElement) observer.observe(currentElement);

    return () => observer.unobserve(currentElement);
  }, [element, rootMargin]);

  return isVisible;
};
