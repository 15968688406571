import { useState } from "react";
import Button from "@material-ui/core/Button";

import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import axios from "axios";

import FooterSection from "../FooterSection/Footer";
import Header from "../HeaderSection/Header";
import { Box } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: "25ch",
    },
  },
}));

const Contact = () => {
  const [gtpSystem, setGtpSystem] = useState();
  const [pickStations, setPickStations] = useState();
  const [pickNumber, setPickNumber] = useState();
  const [comment, setComment] = useState();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [email, setEmail] = useState();
  const [company, setCompany] = useState();
  const [success, setSuccess] = useState(false);
  const [warning, setWarning] = useState(null);

  const sendEmail = () => {
    if (email && firstName && lastName && company) {
      axios
        .post(`/send-mail`, {
          firstName: firstName,
          lastName: lastName,
          pickStations: pickStations,
          company: company,
          email: email,
          comment: comment,
          numberPicks: pickNumber,
          numberPickStations: pickStations,
          gtpSystem: gtpSystem,
        })
        .then(
          (response) => {
            console.log(response);
            setSuccess(true);
            setWarning(null);
          },
          (error) => {
            console.log(error);
          }
        );
    } else {
      setWarning("Please fill out all required fields.");
    }
  };

  const handleGtpChange = (event) => {
    setGtpSystem(event.target.value);
  };

  const handlePickStations = (event) => {
    setPickStations(event.target.value);
  };

  const handlePickNumber = (event) => {
    setPickNumber(event.target.value);
  };

  const handleComment = (event) => {
    setComment(event.target.value);
  };

  const handleFirstName = (event) => {
    setFirstName(event.target.value);
  };

  const handleLastName = (event) => {
    setLastName(event.target.value);
  };

  const handleCompany = (event) => {
    setCompany(event.target.value);
  };

  const handleEmail = (event) => {
    setEmail(event.target.value);
  };

  const classes = useStyles();

  return (
    <div className="homepage-6">
      <div id="scrollUp" title="Scroll To Top">
        <i className="fas fa-arrow-up" />
      </div>

      <div className="main">
        <Header imageData={"/img/logo-white.webp"} />
        <section
          className="bg-white section discover-area"
          id="features"
          style={{ paddingTop: 100 }}
        >
          <div className="inner-container" style={{ marginBottom: 60 }}>
            <div className="row">
              <div className="order-1 col-lg-6 col-md-12 order-lg-1">
                <div className="pt-4 discover-text pt-lg-0">
                  <h2 className="pb-4">
                    <span>Automate </span>
                    <br />

                    <span
                      style={{
                        display: "flex",
                      }}
                    >
                      with&nbsp;
                      <span
                        style={{
                          color: "#3fcfab",
                          fontWeight: 500,
                          display: "flex",
                        }}
                      >
                        Nimble
                        <span
                          style={{
                            fontSize: 20,
                          }}
                        >
                          ®
                        </span>
                      </span>
                    </span>
                  </h2>
                  <p>
                    Contact us to see how we can help with intelligent
                    automation at your facility. <br /> Fill out the form and a
                    member of our team will reach out within 24 hours.
                  </p>
                  {/* <div style={{ marginTop: 60 }}>
                    <p>488 8th St.</p>
                    <p>San Francisco, CA 94103</p>
                    <p>Phone</p>
                    <p>555-555-5555</p>
                  </div> */}
                </div>
              </div>
              <div className="order-1 col-lg-6 col-md-12 order-lg-1">
                {!success ? (
                  <div className="pt-4 discover-text pt-lg-0">
                    <form
                      className={classes.root}
                      noValidate
                      autoComplete="off"
                    >
                      <div>
                        <TextField
                          required
                          id="firstName"
                          label="First Name"
                          onChange={handleFirstName}
                        />
                        <TextField
                          required
                          id="lastName"
                          label="Last Name"
                          onChange={handleLastName}
                        />
                        <TextField
                          required
                          id="email"
                          label="Email"
                          onChange={handleEmail}
                        />
                        <TextField
                          required
                          id="company"
                          label="Company"
                          onChange={handleCompany}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Box display="flex" alignItems="center">
                          <Box style={{ marginLeft: 8 }}>
                            Goods to person system
                          </Box>
                          <Select
                            labelId="Goods to Person System"
                            id="gtpSystem"
                            value={gtpSystem}
                            onChange={handleGtpChange}
                            style={{ margin: 8 }}
                          >
                            <MenuItem value={"AutoStore"}>AutoStore</MenuItem>
                            <MenuItem value={"Perfect Pick"}>
                              Perfect Pick
                            </MenuItem>
                            <MenuItem value={"TGW Pick Center One"}>
                              TGW Pick Center One
                            </MenuItem>
                            <MenuItem value={"Knapp"}>Knapp</MenuItem>
                            <MenuItem value={"Dematic"}>Dematic</MenuItem>
                            <MenuItem value={"Vanderlande"}>
                              Vanderlande
                            </MenuItem>
                            <MenuItem value={"Other"}>Other</MenuItem>
                          </Select>
                        </Box>
                        <Box display="flex" alignItems="center">
                          <Box style={{ marginLeft: 8 }}>
                            Number of pick stations
                          </Box>
                          <Select
                            labelId="Number of pick stations"
                            id="pickStations"
                            value={pickStations}
                            onChange={handlePickStations}
                            style={{ margin: 8 }}
                          >
                            <MenuItem value={"0-5"}>0-5</MenuItem>
                            <MenuItem value={"5-10"}>5-10</MenuItem>
                            <MenuItem value={"10-20"}>10-20</MenuItem>
                            <MenuItem value={"20+"}>20+</MenuItem>
                          </Select>
                        </Box>
                        <Box display="flex" alignItems="center">
                          <Box style={{ marginLeft: 8 }}>
                            Number of daily picks
                          </Box>
                          <Select
                            labelId="Number of picks per day"
                            id="pickNumber"
                            value={pickNumber}
                            onChange={handlePickNumber}
                            style={{ margin: 8 }}
                          >
                            <MenuItem value={"0-10,000"}>0-10,000</MenuItem>
                            <MenuItem value={"10,000-20,000"}>
                              10,000-20,000
                            </MenuItem>
                            <MenuItem value={"20,000-50,000"}>
                              20,000-50,000
                            </MenuItem>

                            <MenuItem value={"50,000-100,000"}>
                              50,000-100,000
                            </MenuItem>
                            <MenuItem value={"100,000+"}>100,000+</MenuItem>
                          </Select>
                        </Box>
                      </div>
                      <div style={{ width: "100%" }} id="use-case">
                        <TextField
                          id="useCase"
                          style={{ width: "100%", margin: 8, maxHeight: 150 }}
                          helperText="Tell us about your use case"
                          margin="normal"
                          multiline
                          maxRows={5}
                          onChange={handleComment}
                          value={comment}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="outlined"
                        />
                      </div>
                      <div>
                        <Button
                          variant="contained"
                          style={{ marginLeft: 8, marginTop: 20 }}
                          onClick={() => sendEmail("test")}
                        >
                          Submit
                        </Button>
                      </div>
                      {warning !== null && (
                        <div className="warning-message">{warning}</div>
                      )}
                    </form>
                  </div>
                ) : (
                  <h3>Thank you. We will reach out to you soon!</h3>
                )}
              </div>
            </div>
          </div>
        </section>
        <FooterSection />
      </div>
    </div>
  );
};

export default Contact;
