import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import React, { useEffect, useState } from "react";

import { AnimatedCursor } from "./../AnimatedCursor";
import styles from "./MainContent.module.css";

const DiscoverSection = () => {
  const [selectedVideoBox, setSelectedVideoBox] = useState("thum1");
  const [width, setWidth] = useState(window.innerWidth);

  let iOS = (() => {
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
    // iPad on iOS 13 detection
    || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  })();

  function handleWindowSizeChange() {
      setWidth(window.innerWidth);
  }
  useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
      }
  }, []);
  
  let isMobile = (width <= 768);
  const defaultVideoHeader = "Goods-to-Robot";
  const defaultVideoText =
    "Automate picking from a goods-to-person system. Retro-fit existing pick station or incorporate in greenfield warehouse.";

  const [videoHeader, setVideoHeader] = useState(defaultVideoHeader);
  const [videoText, setVideoText] = useState(defaultVideoText);

  useEffect(() => {
    const hrElement = document.querySelector(".trans--grow");
    setTimeout(function () {
      hrElement.classList.add("grow");
    }, 175);
  }, []);

  const setBackgroundVideo = (thum) => {
    setSelectedVideoBox(thum);

    switch (thum) {
      case "thum1":
        setVideoHeader(`Goods-to-Robot`);
        setVideoText(
          `Automate picking from a goods-to-person system. Retro-fit existing pick station or incorporate in greenfield warehouse.`
        );
        break;
      case "thum2":
        setVideoHeader(`Put-Wall Sorting`);
        setVideoText(
          `Automate sorting with a flexible sorting system that singulates items from a tote or chute, scans the items and sorts them to a customer's order cubby.`
        );
        break;
      default:
        setVideoHeader(defaultVideoHeader);
        setVideoText(defaultVideoText);
    }
  };

  const [showCursor, setShowCursor] = useState(false);

  return (
    <>
      <div
        style={{
          opacity: showCursor ? 1 : 0,
          transition: "opacity 0.3s ease-in-out",
        }}
      >
        <AnimatedCursor />
      </div>
      <section
        className="bg-white section discover-area"
        id="features"
        style={{ paddingTop: 60 }}
      >
        <div className="inner-container" style={{ marginBottom: 60 }}>
          <div className="row">
            <div className="order-1 col-12 order-lg-1">
              {/* Discover Text */}
              <div className="pt-4 discover-text pt-lg-0">
                <h2 className="pb-4">
                  <span>
                    <span
                      style={{
                        color: "#3fcfab",
                        fontWeight: 500,
                      }}
                    >
                      Nimble<sup>&reg;</sup>
                    </span>
                  </span>
                  <span>
                    <span
                      style={{
                        color: "#222",
                      }}
                    >
                      &nbsp;is reimagining fulfillment&nbsp;
                    </span>
                  </span>
                  <br className={styles.Break} />
                  <span>
                    with intelligent robots that can{" "}
                    <br className={styles.Break} /> pick and pack anything.
                  </span>
                </h2>
              </div>
            </div>
          </div>
          <div>
            <hr className="trans--grow hr2" />
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ marginTop: 30 }}>
              <p className="alt-p">_The future of fulfillment is here</p>
            </div>
            <div style={{ marginTop: 30 }}>
              <a href="#video">
                <p style={{ fontWeight: "normal", fontSize: 14 }}>
                  Scroll down
                  <ArrowDownwardIcon
                    style={{
                      fontSize: 14,
                      position: "relative",
                      bottom: 3,
                      marginLeft: 20,
                    }}
                  />
                </p>
              </a>
            </div>
          </div>
        </div>

        <div
          style={{ 
            display: "flex",
          }}
          id="solutions"
          onMouseEnter={() => setShowCursor(true)}
          onMouseLeave={() => setShowCursor(false)}
        >
          <div
            style={{
              width: "100%",
              position: "relative",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <video
              loop
              autoPlay={true}
              playsInline
              preload="auto"
              muted
              className="video-content"
              style={{
                display: selectedVideoBox === "thum2" && "none",
              }}
              key="/img/solution-video"
            >
              <source src={isMobile ? (iOS ? "/img/solution-m.mp4" : "/img/solution-m.webm") : "/img/solution.webm"}
                      type={iOS ?  "video/mp4;codecs=\"avc1\"" : "video/webm;codecs=\"vp9\""} />
            </video>
            <video
              ref={(e)=>{
                if(e != null) {
                  e.currentTime=119;
                  e.play();
                }
              }}
              // loop
              autoPlay={false}
              playsInline
              preload="auto"
              muted
              onEnded={(e)=>{
                e.target.currentTime = 119;
                e.target.play();
              }}
              className="video-content"
              style={{
                width: "100%",
                height: "100%",
                display: selectedVideoBox === "thum1" && "none",
              }}
              key="video-putwall"
            >
              <source src={isMobile ? (iOS ? "/img/solution-m.mp4" : "/img/solution-m.webm") : "/img/solution.webm"}
                      type={iOS ?  "video/mp4;codecs=\"avc1\"" : "video/webm;codecs=\"vp9\""} />
            </video>
            <div className="video-overlay-black">
              <div
                className="overlay-left"
                onClick={() => setBackgroundVideo("thum1")}
              />
              <div
                className="overlay-right"
                onClick={() => setBackgroundVideo("thum2")}
              />
            </div>
            <div className="video-text">
              <div style={{ zIndex: 10, background: "transparent" }}>
                <h3 className="video-overlay-header">{videoHeader}</h3>
                <div className="video-overlay-text">
                  <span>{videoText}</span>
                </div>
                <a href="/contact-us" className="video-overlay-button">
                  Learn More <ArrowForwardIcon style={{ marginLeft: 20 }} />
                </a>
              </div>
              <div
                className="video-tiles-parent"
                style={{ display: "flex", zIndex: 10, cursor: "pointer" }}
              >
                <div>
                  <div
                    className="video-tile"
                    style={{ backgroundImage: `url('img/thum1.webp')` }}
                    onClick={() =>
                      setBackgroundVideo("thum1")
                    }
                  >
                    <div
                      className={
                        selectedVideoBox === "thum2"
                          ? `video-black-overlay`
                          : null
                      }
                    />
                  </div>
                  <div
                    style={{ marginTop: 20 }}
                    className={`thum-text ${
                      selectedVideoBox === "thum1" && "thum-active"
                    }`}
                  >
                    <span
                      className={`thum-text ${
                        selectedVideoBox === "thum1" && "active-text"
                      }`}
                    >
                      Goods-to-Robot
                    </span>
                  </div>
                </div>
                <div>
                  <div
                    className="video-tile"
                    style={{ backgroundImage: `url('img/thum2.webp')` }}
                    onClick={() =>
                      setBackgroundVideo("thum2")
                    }
                  >
                    <div
                      className={
                        selectedVideoBox === "thum1"
                          ? `video-black-overlay`
                          : null
                      }
                    />
                  </div>
                  <div
                    style={{ marginTop: 20 }}
                    className={`thum-text ${
                      selectedVideoBox === "thum2" && "thum-active"
                    }`}
                  >
                    <span
                      className={`thum-text ${
                        selectedVideoBox === "thum2" && "active-text"
                      }`}
                    >
                      Put-Wall Sorting
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.RightOverlayBar}>
            <span onClick={()=>{
              if(!isMobile) return;
              if(selectedVideoBox === "thum1"){
                setBackgroundVideo("thum2")
              } else {
                setBackgroundVideo("thum1")
              }
            }}>[Nimble Solutions]</span>
          </div>
        </div>
      </section>
    </>
  );
};

export default DiscoverSection;
