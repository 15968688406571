export const LeadMemebers = [
  {
    name: "Simon Kalouche",
    title: "Founder, CEO",
    photo: `/img/team/simon.webp`,
    linkedIn: "https://www.linkedin.com/in/simon-kalouche-98883343/",
    companies: [
      {
        name: "Stanford University",
        logo: "https://identity.stanford.edu/wp-content/uploads/2020/07/block-s-right.webp",
      },
      {
        name: "NASA",
        logo: "https://upload.wikimedia.org/wikipedia/commons/e/e5/NASA_logo.svg",
      },
      {
        name: "Carnegie Mellon University",
        logo: "/img/brands/mellon-u.webp",
      },
    ],
    exp: [
      "Stanford PhD AI Researcher",
      "Carnegie Mellon M.S. Robotics",
      "NASA JPL Robotics Engineer",
    ],
    logo: "/img/team/simon_logo.webp",
  },
  {
    name: "Sebastian Thrun",
    title: "Board Director",
    photo: `/img/team/sebastian.webp`,
    linkedIn: "https://en.wikipedia.org/wiki/Sebastian_Thrun",
    companies: [
      {
        name: "WAYMO",
        logo: "https://upload.wikimedia.org/wikipedia/commons/9/9f/Waymo_logo.svg",
      },
      {
        name: "X",
        logo: "/img/brands/x.webp",
      },
      {
        name: "Standford University",
        logo: "https://identity.stanford.edu/wp-content/uploads/2020/07/block-s-right.webp",
      },
    ],
    exp: [
      "Founder of GoogleX and Waymo",
      "Founder of Kitty Hawk and Udacity",
      "Stanford Professor “Father of autonomous vehicles”",
    ],
    logo: "/img/team/sebastian_logo.webp",
  },
  {
    name: "Fei-Fei Li",
    title: "Board Director",
    photo: `/img/team/feifei.webp`,
    linkedIn: "https://en.wikipedia.org/wiki/Fei-Fei_Li",
    companies: [
      {
        name: "Google",
        logo: "https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg",
      },
      {
        name: "Twitter",
        logo: "https://upload.wikimedia.org/wikipedia/commons/9/9a/Font_Awesome_5_brands_Twitter.svg",
      },
      {
        name: "Stanford University",
        logo: "https://identity.stanford.edu/wp-content/uploads/2020/07/block-s-right.webp",
      },
    ],
    exp: [
      "Chief Scientist of AI, Google",
      "Director Stanford AI Lab",
      "Board Director, Twitter",
    ],
    logo: "/img/team/feifei_logo.webp",
  },
];

export const TeamMembers = [
  {
    name: "Jennifer Johnston",
    title: "CFO & COO",
    photo: `/img/team/jennifer.webp`,
    linkedIn: "https://www.linkedin.com/in/jenniferjohnstonwa/",
    companies: [],
    exp: [
      "Director of Finance (CFO), AWS Worldwide Marketing & Public Sector Finance",
      "Acting Global Supply Chain Finance Manager, Measurement Solutions",
    ],
    logo: "/img/team/jennifer_logo.webp",
  },
  {
    name: "Jordan Dawson",
    title: "VP Operations",
    photo: `/img/team/jordan.webp`,
    linkedIn: "https://www.linkedin.com/in/jordan-dawson-28377439/",
    companies: [
      {
        name: "BATTELLE",
        logo: "https://upload.wikimedia.org/wikipedia/commons/1/1a/BATTELLE-BLUE.svg",
      },
      {
        name: "FISHER",
        logo: "/img/brands/fisher.webp",
      },
    ],
    exp: [
      "MBA, B.S. Finance, Ohio State University",
      "FP&A Manager, PandaDoc FP&A and Logistics, Scotts Miracle Grow",
    ],
    logo: "/img/team/jordan_logo.webp",
  },
  {
    name: "Melissa Curry",
    title: "VP Fulfillment Operations",
    photo: `/img/team/melissa.webp`,
    linkedIn: "https://www.linkedin.com/in/melissa-curry-7607a65/",
    companies: [
      {
        name: "PUMA",
        logo: "https://upload.wikimedia.org/wikipedia/commons/f/fd/Logo_Puma.webp",
      },
      {
        name: "NFI",
        logo: "https://cdn.worldvectorlogo.com/logos/nfi-industries.svg",
      },
    ],
    exp: [
      "10+ years Director of Fulfillment Operations NFI",
      "GM Fulfillment Centers",
      "MBA Pepperdine",
    ],
    logo: "/img/team/melissa_logo.webp",
  },
  {
    name: "Brett Kennedy",
    title: "Chief Engineer Advisor",
    photo: `/img/team/brett.webp`,
    linkedIn: "https://www.linkedin.com/in/brett-kennedy-340a1b1b/",
    companies: [
      {
        name: "Stanford University",
        logo: "https://identity.stanford.edu/wp-content/uploads/2020/07/block-s-right.webp",
      },
      {
        name: "NASA",
        logo: "https://upload.wikimedia.org/wikipedia/commons/e/e5/NASA_logo.svg",
      },
    ],
    exp: [
      "23 years Deputy Manager, Robotics at NASA",
      "Cognizant Engineer, Mars Rover",
      "Chief Engineer and Principal, NASA",
      "M.S. Stanford, B.S. Berkeley",
    ],
    logo: "/img/team/brett_logo.webp",
  },
  {
    name: "Matt Shekels",
    title: "VP of Hardware",
    photo: `/img/team/matt.webp`,
    linkedIn: "https://www.linkedin.com/in/matthew-shekels-4518b171/",
    companies: [
      {
        name: "NASA",
        logo: "https://upload.wikimedia.org/wikipedia/commons/e/e5/NASA_logo.svg",
      },
      {
        name: "Walt Desiney",
        logo: "https://upload.wikimedia.org/wikipedia/commons/7/75/Walt_Disney_Imagineering_%282019%29.svg",
      },
    ],
    exp: ["Lead Mechanical Engineer NASA, JPL Robotics"],
    logo: "/img/team/matt_logo.webp",
  },
  {
    name: "Jonathan Briggs",
    title: "VP of Enterprise Sales",
    photo: `/img/team/jonathan.webp`,
    linkedIn: "https://www.linkedin.com/in/jdbriggs/",
    companies: [
      {
        name: "NASA",
        logo: "https://upload.wikimedia.org/wikipedia/commons/e/e5/NASA_logo.svg",
      },
      {
        name: "Walt Desiney",
        logo: "https://upload.wikimedia.org/wikipedia/commons/7/75/Walt_Disney_Imagineering_%282019%29.svg",
      },
    ],
    exp: ["Director, DHL eCommerce Fulfillment Solutions",
      "Strategic Account Manager, FedEx",
      "Account Executive, UPS",],
    logo: "/img/team/jonathan_logo.webp",
  },
  {
    name: "George Marchman",
    title: "Director of Engineering",
    photo: `/img/team/george.webp`,
    linkedIn: "https://www.linkedin.com/in/george-marchman-27b43a64/",
    companies: [
      {
        name: "MANHATTAN",
        logo: "/img/brands/manhattan.webp",
      },
    ],
    exp: ["Sr. Engineering Manager, Manhattan", "Lead Consultant, Manhattan"],
    logo: "/img/team/george_logo.webp",
  },
  {
    name: "E. Gil Jones",
    title: "Director of Robotics",
    photo: `/img/team/gil.webp`,
    linkedIn: "https://www.linkedin.com/in/e-gil-jones-4087083/",
    companies: [],
    exp: ["Sr. Software Engineer, Waymo",
      "Sr. Software Engineer, Google",
      "Sr. Research Engineer, Willow Garage",
      "Ph.D. in Robotics, Carnegie Mellon University"],
    logo: "/img/team/gil_logo.webp",
  },
  {
    name: "Jared Petrie",
    title: "Director of Software Tooling and Validation",
    photo: `/img/team/jared.webp`,
    linkedIn: "https://www.linkedin.com/in/jspetrie/",
    companies: [],
    exp: [
      "Head of Software Validation, Zipline",
      "Manager, Firmware Validation, Zoox",
      "Manager, Firmware Engineering, Tesla"],
    logo: "/img/team/jared_logo.webp",
  },
  {
    name: "Siva Mynepalli",
    title: "Head of Computer Vision",
    photo: `/img/team/siva.webp`,
    linkedIn: "https://www.linkedin.com/in/sivachaitanyamynepalli/",
    companies: [
      {
        name: "SAMSUNG",
        logo: "https://upload.wikimedia.org/wikipedia/commons/b/b4/Samsung_wordmark.svg",
      },
      {
        name: "Carnegie Mellon University",
        logo: "/img/brands/mellon-u.webp",
      },
    ],
    exp: [
      "Sr. Computer Vision Engineer, Samsung M.S. Robotics, Carnegie Mellon B.S. EECS, IIT Bombay",
    ],
    logo: "/img/team/siva_logo.webp",
  },
  {
    name: "Andrii Onoshchenko",
    title: "Business Operations",
    photo: `/img/team/andrii.webp`,
    linkedIn: "https://www.linkedin.com/in/onoshchenko/",
    exp: [
      "Manager of Operations & Strategy at Databricks",
      "BizOps and Strategy Manager at PandaDoc",
    ],
    logo: "/img/team/andrii_logo.webp",
  },
  {
    name: "Saket Chatrath",
    title: "Director of Operations, GM",
    photo: `/img/team/saket.webp`,
    linkedIn: "https://www.linkedin.com/in/saket-chatrath-696a0717/",
    exp: [
      "GM, Walmart eCommerce fulfillment",
      "Multi-Site leader, Amazon Robotics Fulfillment Mega Center",
    ],
    logo: "/img/team/saket_logo.webp",
  },
  {
    name: "Mike Whitaker",
    title: "Head of Talent",
    photo: `/img/team/mike.webp`,
    linkedIn: "https://www.linkedin.com/in/michaelwhitaker12/",
    exp: [
      "Senior Business Development Manager, UDig",
      "Enterprise Account Executive, Celerity",
      "Technical Recruiter, TEKsystems",
    ],
    logo: "/img/team/mike_logo.webp",
  },
];
