import styles from "./Hero.module.css";
import React, { useEffect, useState } from "react";

export const HeroSection = () => {
  const [width, setWidth] = useState(window.innerWidth);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  let isMobile = (width <= 768);

  let iOS = (() => {
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
      // iPad on iOS 13 detection
      || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  })();

  return (
    <section id="home" className="section">
      <div className={styles.HeroVideo}>
        <video
          autoPlay
          playsInline
          muted
          preload="auto"
          loop>
          <source src={isMobile ? (iOS ? "/img/banner-m.mp4" : "/img/banner-m.webm") : "/img/banner.webm"}
            type={iOS ? "video/mp4;codecs=\"avc1\"" : "video/webm;codecs=\"vp9\""} />
        </video>
      </div>
      <div className="video-overlay" />
    </section>
  );
};
