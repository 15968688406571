export const blogItems = [
  {
    title: "Imperial College Of London",
    image: "/img/domain_rand.webm",
    video: "https://www.youtube.com/watch?v=k7dom7fRb8I&feature=youtu.be",
  },
  {
    title: "MIT",
    image: "/img/minicheetah.webm",
    video:
      "http://news.mit.edu/2019/mit-mini-cheetah-first-four-legged-robot-to-backflip-0304",
  },
  {
    title: "OpenAI",
    image: "/img/real_rubiks.webm",
    video: "https://openai.com/blog/solving-rubiks-cube/",
  },
  {
    title: "Princeton And Google",
    image: "/img/tossingbot.webm",
    video: "https://tossingbot.cs.princeton.edu/",
  },
  {
    title: "Stanford AI Lab",
    image: "/img/stanford.webm",
    video: "https://yuanshenli.com/roller_grasper_v2.html",
  },
  {
    title: "Boston Dynamics",
    image: "/img/boston.webm",
    video: "https://www.youtube.com/channel/UC7vVhkEfw4nOGp8TyDk7RcQ",
  },
  {
    title: "Ascento - ETH Zurich",
    image: "/img/ascento.webm",
    video: "https://www.ascento.ch/",
  },
  {
    title: "Google",
    image: "/img/google.webm",
    video: "https://transporternets.github.io",
  },
];
