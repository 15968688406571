import React, { Component } from "react";
import styles from "./Footer.module.css";

class FooterSection extends Component {
  render() {
    return (
      <div>
        <footer>
          <div>
            <div className="container">
              <div className="row">
                <div className="col-sm-12 col-md-3">
                  <div className="py-4">
                    <div>
                      <img
                        className="navbar-brand-regular"
                        src="/img/logo.webp"
                        alt="Nimble.ai"
                        style={{ width: 120 }}
                      />
                    </div>
                    <div className="footer-button-container">
                      <a href="mailto:info@nimble.ai">
                        <div className={styles.NimbleButton}>
                          Request a Demo
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-sm-4 col-md-3">
                  <div className="py-4">
                    <div className="mb-4 footer-header">Quick Links </div>
                    {/* <div className="mt-2">
                      <a href="/">Technology</a>
                    </div> */}
                    <div className="mt-2">
                      <a href="/about-us">About</a>
                    </div>
                    <div className="mt-2">
                      <a
                        href="/careers"
                      >
                        Careers
                      </a>
                    </div>
                    <div className="mt-2">
                      <a href="/#blog">Blog</a>
                    </div>
                  </div>
                </div>
                <div className="col-sm-4 col-md-3">
                  <div className="py-4">
                    <div className="mb-4 footer-header">Solutions</div>
                    <div className="mt-2">
                      <a href="/#solutions">Goods-to-robot</a>
                    </div>
                    <div className="mt-2">
                      <a href="/#solutions">Put-Wall Sorting</a>
                    </div>
                  </div>
                </div>
                <div className="col-sm-4 col-md-3">
                  <div className="py-4">
                    <div className="mb-4 footer-header">Social Media </div>
                    <div className="mt-2">
                      <a
                        href="https://twitter.com/nimble_ai"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Twitter
                      </a>
                    </div>
                    <div className="mt-2">
                      <a
                        href="https://www.linkedin.com/company/nimble-robotics/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        LinkedIn
                      </a>
                    </div>
                    <div className="mt-2">
                      <a
                        href="https://www.youtube.com/channel/UCQ3jRmtiUGymyLxWpbjtlwg"
                        target="_blank"
                        rel="noreferrer"
                      >
                        YouTube
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-4 footer-copyright">
                <p>Copyright © 2021-22 Nimble. All Rights Reserved.</p>
                {/* <p>488 8th St. - San Francisco, CA 94103</p> */}
              </div>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}

export default FooterSection;
